/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContributorResponse } from '../models/ContributorResponse';
import type { WeekContributionsRequest } from '../models/WeekContributionsRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DashboardService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns ContributorResponse
   * @throws ApiError
   */
  public getWeekContributions({
    requestBody,
  }: {
    requestBody: WeekContributionsRequest;
  }): CancelablePromise<Array<ContributorResponse>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/dashboard/week-contributions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
