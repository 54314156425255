import cronTime from 'cron-time-generator';
import { format, setHours, setMinutes, subMinutes } from 'date-fns';
import { range } from 'ramda';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { NotificationResponse } from 'src/api';
import useUpdateNotificationSetting from 'src/api/hooks/mutations/notificationSettings/useUpdateNotificationSetting';
import Button from 'src/components/Button';
import Checkbox from 'src/components/fields/Checkbox';
import DescriptionField from 'src/components/fields/DescriptionField';
import Select from 'src/components/fields/Select';
import FormDataRow from 'src/components/FormDataRow';
import Title from 'src/components/Title';
import WithLoader from 'src/components/utils/WithLoader';
import { TIME_SELECT_VALUES } from 'src/pages/OrdersPage/ServiceForm/constants';
import { NotificationSettingsSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';

export type CustomerBeforeServiceSmsFormProps = {
  data: NotificationResponse;
};

const DAYS_SELECT_OPTIONS = range(1, 14);

const timeOptionToCron = (time: string): string => {
  const [hours, minutes] = time.split(':').map(Number);
  const date = setMinutes(setHours(new Date(), hours), minutes);

  return cronTime.everyDayAt(date.getUTCHours(), date.getUTCMinutes());
};

const cronToTimeOption = (cron: string): string => {
  const [munutes, hours] = cron.split(' ');
  const dateUtc = setMinutes(setHours(new Date(), Number(hours)), Number(munutes));
  const date = subMinutes(dateUtc, dateUtc.getTimezoneOffset());

  return format(date, 'HH:mm');
};

const notificationSettingsToFormValues = (data: NotificationResponse): NotificationSettingsSchema => ({
  text: data.text,
  title: null,
  targets: null,
  daysBeforeEvent: data.daysBeforeEvent,
  time: cronToTimeOption(data.cron),
  defaultEnabled: !!data.defaultEnabled,
});

const CustomerBeforeServiceSmsForm: FC<CustomerBeforeServiceSmsFormProps> = ({ data }) => {
  const { control, handleSubmit } = useForm<NotificationSettingsSchema>({
    resolver: zodResolver(NotificationSettingsSchema),
    defaultValues: notificationSettingsToFormValues(data),
  });

  const updateNotificationSettings = useUpdateNotificationSetting();

  const onSubmit: SubmitHandler<NotificationSettingsSchema> = (formData) => {
    const { time, daysBeforeEvent, text, defaultEnabled } = formData;
    const cron = timeOptionToCron(time);

    updateNotificationSettings.mutate({ id: data.id, daysBeforeEvent, text, cron, defaultEnabled });
  };

  return (
    <WithLoader isLoading={updateNotificationSettings.isLoading}>
      <div className='flex flex-col'>
        <Title className='text-2xl mb-8' data-joyride='notifications.customer_sms'>
          <FormattedMessage id='app.notification.customer_before_service' />
        </Title>
        <span className='mb-8'>
          <FormattedMessage id='app.notification.customer_before_service.description' />
        </span>
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
          <FormDataRow label={<FormattedMessage id='app.notification.days_before_event' />}>
            <Controller
              name='daysBeforeEvent'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={DAYS_SELECT_OPTIONS}
                  getOptionLabel={(option) => option.toString()}
                  getOptionValue={(option) => option}
                />
              )}
            />
          </FormDataRow>
          <FormDataRow label={<FormattedMessage id='app.common.time' />}>
            <Controller
              name='time'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={TIME_SELECT_VALUES}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                />
              )}
            />
          </FormDataRow>
          <FormDataRow label={<FormattedMessage id='app.notification.text' />}>
            <Controller name='text' control={control} render={({ field }) => <DescriptionField {...field} />} />
          </FormDataRow>
          <FormDataRow label={<FormattedMessage id='app.notification.default_enabled' />}>
            <Controller name='defaultEnabled' control={control} render={({ field }) => <Checkbox {...field} />} />
          </FormDataRow>
          <Button type='submit'>
            <FormattedMessage id='app.buttons.save' />
          </Button>
        </form>
      </div>
    </WithLoader>
  );
};

export default CustomerBeforeServiceSmsForm;
