import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import PageTitle from 'src/components/PageTitle/PageTitle';

const HomePage: FC = () => {
  return (
    <div className='flex flex-col w-full gap-16'>
      <PageTitle>
        <FormattedMessage id='app.dashboard.page_title' />
      </PageTitle>
    </div>
  );
};

export default HomePage;
