import { FC, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { TenantConfigDto } from 'src/api';
import Alert from 'src/components/common/Alert';
import { CheckboxRhfc } from 'src/components/fields/Checkbox';
import { CheckboxGroupRhfc } from 'src/components/fields/CheckboxGroup';
import { ORDERS_TABLE_COLUMNS } from 'src/pages/OrdersPage/ServiceTable';

const TenantConfigurationOrderTable: FC = () => {
  const { control } = useFormContext<TenantConfigDto>();

  return (
    <>
      <span className='text-2xl mb-4'>
        <FormattedMessage id='app.tenants_page.configuration.order_table' />
      </span>
      <div className='grid grid-cols-2 lg:grid-cols-1 gap-x-20 gap-y-8 border-b broder-gray-300 w-auto'>
        <div className='flex flex-col gap-y-6'>
          <span className='text-2xl'>
            <FormattedMessage id='app.tenants_page.configuration.order_table.default_visible_columns' />
          </span>
          <Alert
            text={<FormattedMessage id='app.tenants_page.configuration.order_table.default_visible_columns.alert' />}
          />
          <CheckboxGroupRhfc
            className='mb-12'
            name={'orderTable.defaultColumns'}
            control={control}
            options={ORDERS_TABLE_COLUMNS.map((column) => ({
              label: (column.header as unknown as () => ReactNode)?.() ?? column.id,
              name: `orderTable.defaultColumns.${column.id}`,
              value: column.id as string,
            }))}
          />
        </div>

        <div className='flex flex-col gap-y-6'>
          <span className='text-2xl'>
            <FormattedMessage id='app.tenants_page.configuration.table.extra' />
          </span>
          <div className='flex w-full gap-3'>
            <FormattedMessage id='app.tenants_page.configuration.table.extra.keepFiltersInLocalStorage' />
            <CheckboxRhfc control={control} name='orderTable.keepFiltersInLocalStorage' />
          </div>
        </div>
      </div>
    </>
  );
};

export default TenantConfigurationOrderTable;
