import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateSavedOrderFormRequest, SavedOrderFormResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';

const useSaveOrderForm = (): UseMutationResult<SavedOrderFormResponse, unknown, CreateSavedOrderFormRequest> => {
  const client = useApiClient();

  return useMutation<SavedOrderFormResponse, unknown, CreateSavedOrderFormRequest>(
    async (form: CreateSavedOrderFormRequest) => {
      const data = await client.savedOrderForms.create({ requestBody: form });
      return data;
    },
    {
      onSuccess: () => {
        toast(<FormattedMessage id='app.toasts.add_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useSaveOrderForm;
