import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContactResponse, CreateContactRequest } from 'src/api';
import { CheckboxRhfc } from 'src/components/fields/Checkbox';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import FormDataRow from 'src/components/FormDataRow';
import PhoneInputLocalized from 'src/components/fields/PhoneInputLocalized';
import Textfield, { TextFieldRhfc } from 'src/components/fields/Textfield';
import AresIcoTextfield from './AresIcoTextfield';
import { ContactSchema } from './schema';
import Select, { SelectRhfc } from 'src/components/fields/Select/Select';
import { CURRENCY_OPTIONS } from 'src/constants/currency';
import useSupportedLanguages from 'src/api/hooks/queries/useSupportedLanguages';
import { MessageId } from 'src/types/commonTypes';
import ToggleB2Cb2B from 'src/components/ToggleB2Cb2B';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import { INTERNAL_CATEGORY_OPTIONS } from 'src/constants/internalCategories';
import { zodResolver } from '@hookform/resolvers/zod';

interface ContactFormProps {
  hideCategory?: boolean;
  defaultValues?: Partial<CreateContactRequest>;
  contactId?: number | string;
  deletedAt?: string | null;
  className?: string;
  onSubmit: SubmitHandler<ContactSchema>;
}

const DEFAULT_VALUES: Partial<CreateContactRequest> = {
  preferredCurrency: 'CZK',
  country: 'cz',
  preferredLanguage: 'cz',
};

const ContactForm: React.FC<ContactFormProps> = ({
  defaultValues,
  hideCategory,
  contactId,
  deletedAt,
  onSubmit,
  className,
}) => {
  const intl = useIntl();
  const form = useForm<ContactSchema>({
    resolver: zodResolver(ContactSchema),
    defaultValues: {
      ...DEFAULT_VALUES,
      ...(!hideCategory && { category: ContactResponse.category.B2B }),
      ...defaultValues,
    },
  });
  const { handleSubmit, control, setValue } = form;

  const { data } = useSupportedLanguages();
  const { data: tenantConfig } = useCurrentTenantConfig();

  useEffect(() => {
    if (!defaultValues) return;
    Object.entries(defaultValues).forEach(([k, v]) => setValue(k as keyof ContactSchema, v));
  }, [defaultValues]);

  return (
    <div className={clsx('flex-col w-full', className)}>
      <FormProvider {...form}>
        <form className='flex flex-col w-full h-full gap-x-24' onSubmit={handleSubmit(onSubmit)}>
          {!hideCategory && (
            <FormDataRow label={<FormattedMessage id='app.customer.category' />}>
              <Controller
                control={control}
                name='category'
                render={({ field }) => <ToggleB2Cb2B value={field.value} onChange={field.onChange} />}
              />
            </FormDataRow>
          )}
          <FormDataRow label={<FormattedMessage id='app.contact.ico' defaultMessage='IČO' />}>
            <Controller
              control={control}
              name='in'
              render={({ field, fieldState }) => (
                <AresIcoTextfield
                  {...field}
                  onIcoSelect={(data) => {
                    setValue('companyName', data.companyName);
                    setValue('city', data.city);
                    setValue('street', data.street);
                    setValue('zip', data.zip);
                    setValue('vat', data.vatNumber);
                  }}
                  error={fieldState.error?.message}
                />
              )}
            />
          </FormDataRow>
          <FormDataRow label={<FormattedMessage id='app.common.vat' defaultMessage='DIČ' />}>
            <Controller
              control={control}
              name='vat'
              render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
            />
          </FormDataRow>

          <FormDataRow
            asterisk
            label={<FormattedMessage id='app.contact.company_name' defaultMessage='Název společnosti' />}
          >
            <Controller
              control={control}
              name='companyName'
              render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
            />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.common.email' defaultMessage='Email' />}>
            <Controller
              control={control}
              name='email'
              render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
            />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.common.phone' defaultMessage='Telefonní číslo' />}>
            <Controller
              control={control}
              name='phoneNumber'
              render={({ field, fieldState }) => <PhoneInputLocalized {...field} error={fieldState.error?.message} />}
            />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.common.preferred_currency' />}>
            <Controller
              control={control}
              name='preferredCurrency'
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  options={CURRENCY_OPTIONS}
                  getOptionLabel={(option) => option}
                  getOptionValue={(option) => option}
                  error={fieldState.error?.message}
                />
              )}
            />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.common.preferred_language' />}>
            <Controller
              control={control}
              name='preferredLanguage'
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  options={data ?? []}
                  getOptionLabel={(option) =>
                    intl.formatMessage({
                      id: `app.language.${option.toLowerCase()}` as MessageId,
                      defaultMessage: option,
                    })
                  }
                  getOptionValue={(option) => option}
                  error={fieldState.error?.message}
                />
              )}
            />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.common.city' />}>
            <Controller
              control={control}
              name='city'
              render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
            />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.common.street' />}>
            <Controller
              control={control}
              name='street'
              render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
            />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.common.zip' />}>
            <Controller
              control={control}
              name='zip'
              render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
            />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.common.country' />}>
            <Controller
              control={control}
              name='country'
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  options={['cz', 'sk']}
                  getOptionValue={(option) => option}
                  getOptionLabel={(option) =>
                    intl.formatMessage({
                      id: `app.country.${option}` as MessageId,
                      defaultMessage: option,
                    })
                  }
                  error={fieldState.error?.message}
                />
              )}
            />
          </FormDataRow>

          {tenantConfig?.contactForm?.showInternalCategory && (
            <FormDataRow label={<FormattedMessage id='app.contact.internal_category' />}>
              <SelectRhfc
                options={INTERNAL_CATEGORY_OPTIONS}
                getOptionLabel={(option) => option}
                getOptionValue={(option) => option}
                control={control}
                name='internalCategory'
              />
            </FormDataRow>
          )}

          {tenantConfig?.contactForm?.showContractNumber && (
            <FormDataRow label={<FormattedMessage id='app.contact.contract_number' />}>
              <TextFieldRhfc control={control} name='contractNumber' />
            </FormDataRow>
          )}

          {contactId && (
            <FormDataRow label={<FormattedMessage id='app.contact.active_contact' />}>
              {deletedAt === null ? (
                <CheckboxRhfc control={control} name='deletedAt' />
              ) : (
                // <Controller
                //   control={control}
                //   name='deletedAt'
                //   render={({ field, fieldState }) => <Checkbox {...field} error={fieldState.error?.message} />}
                // />
                <div>
                  <FormattedMessage id='app.common.yes' defaultMessage='Ano' />
                </div>
              )}
            </FormDataRow>
          )}
          <NewItemPopupFooter />
        </form>
      </FormProvider>
    </div>
  );
};

export default ContactForm;
