import { FC, useCallback, useState } from 'react';
import PageContentHeader from 'src/components/PageContentHeader';
import { FormattedMessage, useIntl } from 'react-intl';
import BackIcon from 'src/components/Icons/BackIcon';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import WithLoader from 'src/components/utils/WithLoader';
import { useApiClient } from 'src/api';
import { write, utils } from 'xlsx';
import useSaveBlob from 'src/hooks/useSaveBlob';
import { Controller, useForm } from 'react-hook-form';
import { omit } from 'ramda';
import PageCard from 'src/components/PageCard';

export type ExportsCustomersOrdersDataPageProps = {
  //
};

export type OrderStatsFormValues = {
  startDate: string | null;
  endDate: string | null;
};

const defaultValues: OrderStatsFormValues = {
  startDate: null,
  endDate: null,
};

const ExportsCustomersOrdersDataPage: FC<ExportsCustomersOrdersDataPageProps> = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const back = useCallback(() => navigate(-1), [navigate]);
  const [isLoading, setIsLoading] = useState(false);
  const apiClient = useApiClient();
  const saveBlob = useSaveBlob();

  const form = useForm<OrderStatsFormValues>({
    defaultValues,
  });
  const { handleSubmit, control } = form;

  const handleDownload = useCallback(async (formData: OrderStatsFormValues) => {
    try {
      setIsLoading(true);
      const data = await apiClient.orders.getCustomersOrdersData(formData);
      const dataWithoutIds = data.map(omit(['id']));
      const hedaerRow = {
        name: intl.formatMessage({ id: 'app.common.name' }),
        ordersCount: intl.formatMessage({ id: 'app.export.orders.custom_export.orders_count' }),
        totalSum: intl.formatMessage({ id: 'app.export.orders.custom_export.total_sum' }),
      };
      const dataWithHeader = [hedaerRow, ...dataWithoutIds];

      const workbook = utils.book_new();
      const worksheet = utils.json_to_sheet(dataWithHeader, { skipHeader: true });
      utils.book_append_sheet(workbook, worksheet);
      const buffer = write(workbook, { type: 'buffer', bookType: 'xlsx' });

      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveBlob(blob, `customers_orders_${new Date().toISOString()}.xlsx`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <WithLoader isLoading={isLoading}>
      <PageContentHeader
        className='my-4'
        title={<FormattedMessage id='app.export.orders.cards.customers_export.title' />}
        hidePrimaryButton={true}
        secondaryButtonText={
          <>
            <BackIcon />
            <FormattedMessage id='app.buttons.back' defaultMessage='Zpět' />
          </>
        }
        onSecondaryButtonClick={back}
      />
      <PageCard>
        <form onSubmit={handleSubmit(handleDownload)}>
          <div className='flex flex-col mb-4'>
            <span className='text-xl font-semibold mb-4'>
              <FormattedMessage id='app.order.real_start_time' />
            </span>
            <div className='flex flex-wrap gap-x-6 gap-y-4'>
              <div className='flex items-center gap-x-1'>
                <FormattedMessage id='app.common.from' />

                <Controller
                  control={control}
                  name='startDate'
                  render={({ field }) => (
                    <input
                      type='date'
                      className='w-full h-10 rounded border border-gray-300 px-3'
                      {...field}
                      value={field.value ?? ''}
                    />
                  )}
                />
              </div>
              <div className='flex  items-center gap-x-1'>
                <FormattedMessage id='app.common.to' />

                <Controller
                  control={control}
                  name='endDate'
                  render={({ field }) => (
                    <input
                      type='date'
                      className='w-full h-10 rounded border border-gray-300 px-3'
                      {...field}
                      value={field.value ?? ''}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <Button type='submit' className='mt-8 max-w-xs' variant='primary'>
            <FormattedMessage id='app.export.orders.custom_export.download_button' />
          </Button>
        </form>
      </PageCard>
    </WithLoader>
  );
};

ExportsCustomersOrdersDataPage.displayName = 'ExportsCustomersOrdersDataPage';

export default ExportsCustomersOrdersDataPage;
