import { createQueryKeys } from '@lukemorales/query-key-factory';
import { PaginationState } from '@tanstack/react-table';
import { BranchFilterQuery } from 'src/api';
import { SortingQuery } from 'src/api/types/shared/sorting';
import {
  ContactFilter,
  DeliveryRateFilter,
  DeliveryTariffFilter,
  MaterialFilter,
  NotificationSettingsFilter,
  OrderTypeFilter,
  PaymentMethodFilter,
  ProductFilter,
  ProductHistoryFilter,
  RoutineServiceFilter,
  ServiceFilter,
  ServiceListsFilter,
  StockOrderFilter,
  TenantFilter,
  UserFilter,
  WorkTariffFilter,
} from 'src/types/filters';

const contacts = createQueryKeys('contacts', {
  list: {
    queryKey: ['list'],
    contextQueries: {
      search: (pagination: PaginationState, filters?: ContactFilter, sorting?: SortingQuery) => [
        {
          pagination,
          filters,
          sorting,
        },
      ],
    },
  },
  detail: (contactId: number) => [contactId],
  history: {
    queryKey: ['history'],
  },
});

const branches = createQueryKeys('branches', {
  list: {
    queryKey: ['list'],
    contextQueries: {
      search: (pagination: PaginationState, filters?: BranchFilterQuery, sorting?: SortingQuery) => [
        {
          pagination,
          filters,
          sorting,
        },
      ],
    },
  },
  listForContact: (contactId: number) => [contactId],
  search: (searchQueryString: string, pagination: PaginationState, sorting: SortingQuery) => [
    { searchQueryString, pagination, sorting },
  ],
  detail: (branchId: number) => [branchId],
});

const employees = createQueryKeys('employees', {
  list: (contactId: number) => [contactId],
  detail: (contactId: number, employeeId: number) => [contactId, employeeId],
});

const products = createQueryKeys('products', {
  list: {
    queryKey: ['list'],
    contextQueries: {
      fromBranch: (branchId: number) => [branchId],
      fromContact: (contactId: number) => [contactId],
      search: (filter: ProductFilter, pagination: PaginationState, sorting: SortingQuery) => [
        filter,
        pagination,
        sorting,
      ],
    },
  },
  search: (searchQueryString: string, pagination: PaginationState) => [{ searchQueryString, pagination }],
  detail: (productId: number) => [productId],
});

const productDocuments = createQueryKeys('productDocuments', {
  list: (productId: number) => [productId],
});

const serviceLists = createQueryKeys('serviceLists', {
  list: (contactId: number) => ({
    queryKey: [contactId],
    contextQueries: {
      search: (filters?: ServiceListsFilter) => [{ filters }],
    },
  }),
});

const orders = createQueryKeys('orders', {
  list: {
    queryKey: ['list'],
    contextQueries: {
      search: (filters?: ServiceFilter, sorting?: SortingQuery, pagination?: PaginationState) => [
        {
          pagination,
          filters,
          sorting,
        },
      ],
    },
  },
  detailedList: {
    queryKey: ['detailedList'],
    contextQueries: {
      search: (filters?: ServiceFilter, sorting?: SortingQuery, pagination?: PaginationState) => [
        {
          pagination,
          filters,
          sorting,
        },
      ],
    },
  },
  detail: (orderId: number) => [orderId],
});

const technicianMonthOrders = createQueryKeys('technicianMonthOrders', {
  list: (technicianId: number, year: number, monthIndex: number) => [technicianId, year, monthIndex],
});

const allTechniciansWeekOrders = createQueryKeys('allTechniciansWeekOrders', {
  list: (year: number, weekIndex: number) => [year, weekIndex],
});

const materials = createQueryKeys('materials', {
  list: {
    queryKey: ['list'],
    contextQueries: {
      search: (filters?: MaterialFilter, sorting?: SortingQuery, pagination?: PaginationState) => [
        {
          pagination,
          filters,
          sorting,
        },
      ],
    },
  },
  detail: (materialId: number) => [materialId],
});

const users = createQueryKeys('users', {
  list: {
    queryKey: ['list'],
    contextQueries: {
      search: (filters?: UserFilter, sorting?: SortingQuery, pagination?: PaginationState) => [
        {
          pagination,
          filters,
          sorting,
        },
      ],
      technicians: null,
    },
  },
  techniciansAvailability: (start: string, end: string) => [start, end],
  current: null,
  detail: (userId: number) => [userId],
});

const userProfile = createQueryKeys('userProfile', {
  detail: null,
});

const paymentMethods = createQueryKeys('paymentMethods', {
  list: {
    queryKey: ['list'],
    contextQueries: {
      search: (filters?: PaymentMethodFilter, sorting?: SortingQuery, pagination?: PaginationState) => [
        {
          pagination,
          filters,
          sorting,
        },
      ],
    },
  },
  detail: (paymentMethodId: number) => [paymentMethodId],
});

const statuses = createQueryKeys('statuses', {
  list: null,
});

const routineServices = createQueryKeys('routineServices', {
  list: {
    queryKey: ['list'],
    contextQueries: {
      search: (filters?: RoutineServiceFilter, sorting?: SortingQuery, pagination?: PaginationState) => [
        {
          pagination,
          filters,
          sorting,
        },
      ],
    },
  },
  detail: (routineServiceId: number) => [routineServiceId],
});

const notificationSettings = createQueryKeys('notificationSettings', {
  list: {
    queryKey: ['list'],
    contextQueries: {
      search: (filters?: NotificationSettingsFilter, sorting?: SortingQuery, pagination?: PaginationState) => [
        {
          pagination,
          filters,
          sorting,
        },
      ],
    },
  },
  detail: (notificationSettingId: number) => [notificationSettingId],
});

const technicianOrder = createQueryKeys('technicianOrder', {
  detail: (orderUrl: string) => [orderUrl],
});

const tenant = createQueryKeys('tenant', {
  current: null,
  list: {
    queryKey: ['list'],
    contextQueries: {
      search: (filters?: TenantFilter, sorting?: SortingQuery, pagination?: PaginationState) => [
        {
          pagination,
          filters,
          sorting,
        },
      ],
    },
  },
  detail: (tenantId: number) => [tenantId],
});

const tenantConfig = createQueryKeys('tenantConfig', {
  detail: (tenantId: number) => [tenantId],
});

const licences = createQueryKeys('licences', {
  list: null,
});

const translations = createQueryKeys('translations', {
  detail: (language: string) => [language],
});

const tenantNotificationsSettings = createQueryKeys('tenantNotificationsSettings', {
  list: null,
});

const countriesLocalization = createQueryKeys('countriesLocalization', {
  detail: (countryCode: string) => [countryCode],
});

const aresData = createQueryKeys('aresData', {
  detail: (ico: string) => [ico],
});

const autocomplete = createQueryKeys('autocomplete', {
  detail: (id, query) => [id, query],
});

const fnsLocale = createQueryKeys('fnsLocale', {
  detail: (language: string) => [language],
});
const productHistory = createQueryKeys('productHistory', {
  list: {
    queryKey: ['list'],
    contextQueries: {
      search: (
        productId: number,
        filters?: ProductHistoryFilter,
        sorting?: SortingQuery,
        pagination?: PaginationState,
      ) => [
        {
          productId,
          pagination,
          filters,
          sorting,
        },
      ],
    },
  },
});

const fileUrl = createQueryKeys('fileUrl', {
  detail: (fileId: number) => [fileId],
});

const translationPacks = createQueryKeys('translationPacks', {
  detail: (language: string) => [language],
  defaultDetail: (language: string) => [language],
  languagesList: null,
  list: null,
});

const customTranslationPacks = createQueryKeys('customTranslationPacks', {
  detail: (language: string) => [language],
  languagesList: null,
  list: null,
});

const pdfTranslationsPack = createQueryKeys('pdfTranslationsPack', {
  detail: (language: string) => [language],
});

const upcomingOrders = createQueryKeys('upcomingOrders', {
  currentUser: null,
});

const deliveryTariffs = createQueryKeys('deliveryTariffs', {
  list: {
    queryKey: ['list'],
    contextQueries: {
      search: (filters?: DeliveryTariffFilter, sorting?: SortingQuery, pagination?: PaginationState) => [
        {
          filters,
          sorting,
          pagination,
        },
      ],
    },
  },
  detail: (deliveryTariffId: number) => [deliveryTariffId],
});

const deliveryRates = createQueryKeys('deliveryRates', {
  list: {
    queryKey: ['list'],
    contextQueries: {
      search: (filters?: DeliveryRateFilter, sorting?: SortingQuery, pagination?: PaginationState) => [
        {
          filters,
          sorting,
          pagination,
        },
      ],
    },
  },
  detail: (deliveryRateId: number) => [deliveryRateId],
});

const workTariffs = createQueryKeys('workTariffs', {
  list: {
    queryKey: ['list'],
    contextQueries: {
      search: (filters?: WorkTariffFilter, sorting?: SortingQuery, pagination?: PaginationState) => [
        {
          filters,
          sorting,
          pagination,
        },
      ],
    },
  },
  detail: (workTariffsId: number) => [workTariffsId],
});

const orderTypes = createQueryKeys('orderTypes', {
  list: {
    queryKey: ['list'],
    contextQueries: {
      search: (filters?: OrderTypeFilter, sorting?: SortingQuery, pagination?: PaginationState) => [
        {
          filters,
          sorting,
          pagination,
        },
      ],
    },
  },
  detail: (orderTypeId: number) => [orderTypeId],
});

const serviceListData = createQueryKeys('serviceListData', {
  detail: (orderId: number) => [orderId],
});

const techniciansOrdersStatistics = createQueryKeys('techniciansOrdersStatistics', {
  detail: ['detail'],
});

const vatTariffs = createQueryKeys('vatTariffs', {
  list: ({ withDeleted }: { withDeleted?: boolean }) => [{ withDeleted }],
});

const headquarters = createQueryKeys('headquarters', {
  detail: (headquartersId: number) => [headquartersId],
  list: null,
});

const productTemplates = createQueryKeys('productTemplates', {
  detail: (productTemplateId: number) => [productTemplateId],
  list: null,
});

const stockOrders = createQueryKeys('stockOrders', {
  detail: (stockOrderId: number) => [stockOrderId],
  list: {
    queryKey: ['list'],
    contextQueries: {
      search: (filters?: StockOrderFilter, pagination?: PaginationState) => [
        {
          filters,
          pagination,
        },
      ],
    },
  },
});

const savedOrderForms = createQueryKeys('savedOrderForms', {
  detail: (orderId: number) => [orderId],
});

const dashboard = createQueryKeys('dashboard', {
  weekContributions: (startOfTheWeek: Date) => [startOfTheWeek],
});

const queryKeysFactory = {
  orders,
  contacts,
  branches,
  products,
  productDocuments,
  employees,
  serviceLists,
  materials,
  users,
  userProfile,
  paymentMethods,
  statuses,
  routineServices,
  notificationSettings,
  technicianOrder,
  tenant,
  licences,
  translations,
  tenantNotificationsSettings,
  countriesLocalization,
  aresData,
  autocomplete,
  fnsLocale,
  productHistory,
  technicianMonthOrders,
  fileUrl,
  translationPacks,
  allTechniciansWeekOrders,
  upcomingOrders,
  deliveryTariffs,
  orderTypes,
  serviceListData,
  techniciansOrdersStatistics,
  workTariffs,
  vatTariffs,
  headquarters,
  productTemplates,
  stockOrders,
  deliveryRates,
  customTranslationPacks,
  savedOrderForms,
  tenantConfig,
  pdfTranslationsPack,
  dashboard,
};

export default queryKeysFactory;
