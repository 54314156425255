import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { addMinutes, format, getWeek, isSameDay, setMinutes } from 'date-fns';
import { FC, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';
import useAllTechnicians from 'src/api/hooks/queries/useAllTechnicians';
import useAllTechniciansWeekOrders from 'src/api/hooks/queries/useAllTechniciansWeekOrders';
import useCalendar, { UseCalendarOptions } from 'src/components/Calendar/hook';
import EventsGrid from 'src/components/EventsGrid';
import DraggableEvent from 'src/components/EventsGrid/components/DraggableEvent';
import HorizontalLinesMTD from 'src/components/EventsGrid/components/HorizontalLinesMTD';
import HoursBarMTD from 'src/components/EventsGrid/components/HoursBarMTD';
import MtdHeader from 'src/components/EventsGrid/components/MtdHeader';
import PotentialEvent from 'src/components/EventsGrid/components/PotentialEvent/PotentialEvent';
import TechniciansBar from 'src/components/EventsGrid/components/TechniciansBar';
import VerticalLinesMTD from 'src/components/EventsGrid/components/VerticalLinesMTD';
import { COLS_PER_HOUR_MTD, MINUTES_PER_COL_MTD } from 'src/components/EventsGrid/constants';
import {
  eventToGridMTDFactory,
  getGridCellDimensionsMTD,
  gridToTimeSpanMTDFactory,
} from 'src/components/EventsGrid/helpers/gridMtd';
import useHandleDropMTDFactory from 'src/components/EventsGrid/hooks/useHandleDropMTDFactory';
import { GridEvent } from 'src/components/EventsGrid/types';
import Loader from 'src/components/utils/Loader';
import { snapToHalfHour } from 'src/helpers/datetime';
import getDailyEvents from 'src/helpers/getDailyEvents';
import orderListItemToGridEvent from 'src/helpers/orderListItemToGridEvents';
import AddServicePopup from 'src/pages/OrdersPage/AddServicePopup';
import { OrderSchema } from 'src/pages/OrdersPage/ServiceForm/schema';

export type AllTechniciansCalendarProps = {
  className?: string;
  disableGridClick?: boolean;
  disableEventPreviewOnClick?: boolean;
  disableDnd?: boolean;
  disableTechnicianPreviewOnClick?: boolean;
  calendarOptions?: UseCalendarOptions;
  potentialEvents?: GridEvent[];
};

const START_HOUR = 8;
const END_HOUR = 20;

const AllTechniciansCalendar: FC<AllTechniciansCalendarProps> = ({
  className,
  disableGridClick,
  disableEventPreviewOnClick,
  disableDnd,
  disableTechnicianPreviewOnClick,
  calendarOptions,
  potentialEvents,
}) => {
  const { data: technicians, isLoading } = useAllTechnicians();
  const calendar = useCalendar(calendarOptions);
  const { focusedDate } = calendar;
  const { data: orders } = useAllTechniciansWeekOrders(
    focusedDate.getFullYear(),
    getWeek(focusedDate, { weekStartsOn: 1 }) - 1,
  );

  const eventToGrid = eventToGridMTDFactory(technicians ?? []);

  const events = useMemo(
    () => [...(orders?.map(orderListItemToGridEvent).flat() ?? []), ...(potentialEvents ?? [])],
    [orders, potentialEvents],
  );

  const handleDropFactory = useHandleDropMTDFactory(events, technicians ?? []);

  const dailyEvents = useMemo(() => getDailyEvents(focusedDate, events ?? []), [focusedDate, events]);

  const [isAddServicePopupOpen, setIsAddServicePopupOpen] = useState(false);
  const [addServicePopupDefaultValues, setAddServicePopupDefaultValues] = useState<Partial<OrderSchema>>({});

  const handleGridClickFactory = (date: Date) => (col: number, row: number) => {
    const technician = technicians?.[row];
    const minutesFromDayStart = snapToHalfHour(START_HOUR * 60 + MINUTES_PER_COL_MTD * col);

    if (!technician) return;

    const start = setMinutes(date, minutesFromDayStart);
    const end = addMinutes(start, 60);

    setAddServicePopupDefaultValues({
      technicianIds: [{ technicianId: technician.id }],
      _date: start,
      _start: format(start, 'HH:mm'),
      _end: format(end, 'HH:mm'),
    });
    setIsAddServicePopupOpen(true);
  };

  // fix scroll bar breaking aligment on windows
  const [scrollBarHeight, setScrollBarHeight] = useState(0);
  const daysContainerRef = useRef<HTMLDivElement>(null);
  const firstDayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!daysContainerRef.current || !firstDayRef.current) return;

    const containerHeight = daysContainerRef.current.getBoundingClientRect().height;
    const firstDayHeight = firstDayRef.current.getBoundingClientRect().height;

    const scrollBarHeight = containerHeight - firstDayHeight;

    setScrollBarHeight(scrollBarHeight);
  }, [daysContainerRef.current, firstDayRef.current]);

  const focusedDayRef = useRef<HTMLDivElement>(null);

  // auto scroll to today
  useEffect(() => {
    if (!focusedDayRef.current) return;

    focusedDayRef.current?.scrollIntoView({ inline: 'start' });
  }, [focusedDayRef.current, focusedDate]);

  if (isLoading) return <Loader />;
  if (!technicians) return <Navigate to='/500' />;

  return (
    <>
      <div className={clsx(className, 'flex gap-x-4 mt-12')}>
        <div
          className='flex flex-col justify-between'
          style={{
            paddingBottom: scrollBarHeight,
          }}
        >
          <div className='flex mt-4 bg-white border border-gray-300 rounded-md overflow-hidden divide-x w-fit'>
            <button
              type='button'
              onClick={calendar.onPrevWeek}
              className='flex items-center justify-center py-2 pl-5 pr-6 text-gray-600 hover:text-gray-900 focus:relative md:w-9 md:px-2 hover:bg-gray-50'
            >
              <span className='sr-only'>
                <FormattedMessage id='app.calendar.previous_month' />
              </span>
              <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
            </button>
            <button
              type='button'
              onClick={calendar.onNextWeek}
              className='flex items-center justify-center py-2 pl-6 pr-5 text-gray-600 hover:text-gray-900 focus:relative md:w-9 md:px-2 hover:bg-gray-50'
            >
              <span className='sr-only'>
                <FormattedMessage id='app.calendar.next_month' />
              </span>
              <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
            </button>
          </div>
          <TechniciansBar
            technicians={technicians}
            disableTechnicianPreviewOnClick={disableTechnicianPreviewOnClick}
            className='self-end'
          />
        </div>
        <div className={clsx('flex gap-x-8 overflow-x-auto')} ref={daysContainerRef}>
          {dailyEvents.map(([date, events], index) => (
            <div
              key={date.getTime()}
              className='flex flex-col border border-gray-200 rounded-lg'
              {...(index === 0 ? { ref: firstDayRef } : isSameDay(date, focusedDate) ? { ref: focusedDayRef } : {})}
            >
              <MtdHeader date={date} className='rounded-t-lg' />
              <EventsGrid
                disableEventPreviewOnClick={disableEventPreviewOnClick}
                className='flex-col flex-auto rounded-lg min-w-[54rem]'
                events={events}
                cols={COLS_PER_HOUR_MTD * (END_HOUR - START_HOUR)} //
                rows={technicians.length}
                startHour={START_HOUR}
                endHour={END_HOUR}
                scrollContainerRef={{ current: null } as RefObject<HTMLElement>}
                eventToGrid={eventToGrid}
                gridToTimeSpan={gridToTimeSpanMTDFactory(date)}
                getGridCellDimensions={getGridCellDimensionsMTD}
                onDrop={handleDropFactory(date)}
                onGridClick={!disableGridClick ? handleGridClickFactory(date) : undefined}
                renderEvent={(event, props) =>
                  event.isPotential ? (
                    <PotentialEvent {...props} {...event} />
                  ) : (
                    <DraggableEvent key={event.id} {...event} {...props} disableDrag={disableDnd} />
                  )
                }
                renderHoursBar={() => <HoursBarMTD />}
                renderHorizontalLines={() => <HorizontalLinesMTD />}
                renderVerticalLines={() => <VerticalLinesMTD />}
              />
            </div>
          ))}
        </div>
      </div>
      <AddServicePopup
        defaultValues={addServicePopupDefaultValues}
        open={isAddServicePopupOpen}
        onClose={() => setIsAddServicePopupOpen(false)}
      />
    </>
  );
};

AllTechniciansCalendar.displayName = 'AllTechniciansCalendar';

export default AllTechniciansCalendar;
